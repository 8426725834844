<template>
  <div>
    <label>{{ exampleQuestionText }}</label>
    <a-table
      :columns="getColumnsData || columns"
      :data-source="getRowsData || data"
      :pagination="{ hideOnSinglePage: true }"
      size="small"
    >
      <span slot="action" slot-scope="text, record">
        <a-checkbox
          v-if="type === 'table-checkbox'"
          :style="radioCheckStyle"
          :name="record.key"
          selected
        >
        </a-checkbox>

        <div v-else class="custom-radio">
          <input type="radio" :name="record.key" />
          <label></label>
        </div>
      </span>
    </a-table>
    <QuestionSettings
      v-if="questionSettings"
      @editItem="editItem"
      @deleteTempQuestion="$emit('deleteTempQuestion')"
      @setAsScreener="$emit('setAsScreener', $event)"
      @saveInLibrary="$emit('saveInLibrary', $event)"
      :langObj="langObj"
      :setAsScreener="setAsScreener"
      :saveInLibrary="saveInLibrary"
      :showSaveInLibrary="showSaveInLibrary"
      :showSetAsScreener="showSetAsScreener"
    ></QuestionSettings>
  </div>
</template>

<script>
import QuestionSettings from "@/components/questions/QuestionSettings.vue";

export default {
  name: "GridExample",
  props: {
    langObj: {
      type: Object,
    },
    exampleQuestionText: {
      type: String,
    },
    options: {
      type: Array,
    },
    subQuestions: {
      type: Array,
    },
    index: {
      type: Number,
    },
    questionType: {
      type: String,
    },
    questionSettings: {
      type: Boolean,
    },
    type: {
      type: String,
      default() {
        return "table-checkbox";
      },
    },
    setAsScreener: {
      type: Boolean,
    },
    showSetAsScreener: {
      type: Boolean,
    },
    saveInLibrary: {
      type: Boolean,
    },
    showSaveInLibrary: {
      type: Boolean,
    },
  },
  components: {
    QuestionSettings,
  },
  data() {
    return {
      radioCheckStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
        marginRight: "0",
      },
      optionsExamples: [
        {
          optionText: this.langObj["s4-customQuestions"].modalGridQExampleCol1,
          dataIndex: this.langObj["s4-customQuestions"].modalGridQExampleCol1,
          key: this.langObj["s4-customQuestions"].modalGridQExampleCol1,
          scopedSlots: { customRender: "action" },
          className: "text-center",
        },
        {
          optionText: this.langObj["s4-customQuestions"].modalGridQExampleCol2,
          dataIndex: this.langObj["s4-customQuestions"].modalGridQExampleCol2,
          key: this.langObj["s4-customQuestions"].modalGridQExampleCol2,
          scopedSlots: { customRender: "action" },
          className: "text-center",
        },
        {
          optionText: this.langObj["s4-customQuestions"].modalGridQExampleCol3,
          dataIndex: this.langObj["s4-customQuestions"].modalGridQExampleCol3,
          key: this.langObj["s4-customQuestions"].modalGridQExampleCol3,
          scopedSlots: { customRender: "action" },
          className: "text-center",
        },
      ],
      subQuestionsExamples: [
        {
          key: "1",
          subQuestionText:
            this.langObj["s4-customQuestions"].modalGridQExampleRow1,
        },
        {
          key: "2",
          subQuestionText:
            this.langObj["s4-customQuestions"].modalGridQExampleRow2,
        },
        {
          key: "3",
          subQuestionText:
            this.langObj["s4-customQuestions"].modalGridQExampleRow3,
        },
        {
          key: "4",
          subQuestionText:
            this.langObj["s4-customQuestions"].modalGridQExampleRow4,
        },
      ],
    };
  },
  computed: {
    getRowsData() {
      const data = this.subQuestions || this.subQuestionsExamples;
      const rowsData = data.map((item, index) => {
        return {
          key: (index + 1).toString(),
          name: item.subQuestionText,
        };
      });
      return rowsData;
    },
    getColumnsData() {
      const data = this.options || this.optionsExamples;
      const columnsData = data.map((item) => {
        return {
          title: item.optionText,
          dataIndex: item.optionText,
          key: item.optionText,
          scopedSlots: { customRender: "action" },
          className: "text-center",
        };
      });
      return [
        {
          dataIndex: "name",
          key: "name",
        },
        ...columnsData,
      ];
    },
  },
  methods: {
    editItem() {
      let info = {
        index: this.index,
        questionType: this.questionType,
      };

      this.$emit("editQuestion", info);
    },
  },
};
</script>

<style lang="scss">
.custom-radio {
  width: 16px;
  height: 16px;
  position: relative;
  margin: 7px auto;

  input {
    cursor: pointer;
    &:checked + label:after {
      opacity: 1;
    }
  }
  label {
    display: block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid var(--hsblue);
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: "";
      background-color: var(--hsblue);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      opacity: 0;
    }
  }
}
.text-center {
  text-align: center !important;
}
</style>
