<template>
  <div>
    <div class="questions-list-preview__cta flex">
      <a-checkbox v-model="localSaveInLibrary" v-if="showSaveInLibrary">
        Save in library</a-checkbox
      >
      <a-checkbox v-model="localSetAsScreener" v-if="showSetAsScreener">
        Set as Screener</a-checkbox
      >
      <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
        <template slot="title"> Edit question </template>
        <button
          class="btn-reset questions-list-preview__item-cta"
          @click="$emit('editItem'), (deleteInfo = false)"
        >
          <font-awesome-icon icon="pencil-alt" />
        </button>
      </a-tooltip>
      <a-tooltip placement="top" overlayClassName="ant-tooltip--small">
        <template slot="title"> Delete question </template>
        <button
          type="button"
          class="btn-reset questions-list-preview__item-cta"
          @click="deleteInfo = true"
        >
          <font-awesome-icon icon="trash-alt" />
        </button>
      </a-tooltip>
    </div>
    <div
      class="questions-list-preview__delete-info"
      :class="{
        'questions-list-preview__delete-info--is-active': deleteInfo,
      }"
    >
      <font-awesome-icon icon="exclamation-circle" />
      <h4 v-html="langObj['s4-customQuestions'].previewDeleteMessage"></h4>
      <div class="questions-list-preview__delete-info-cta">
        <button
          type="button"
          class="btn-reset questions-list-preview__item-cta"
          @click="deleteInfo = false"
          v-html="langObj['s4-customQuestions'].previewDeleteNo"
        ></button>
        <button
          type="button"
          class="
            btn-reset
            questions-list-preview__item-cta
            questions-list-preview__item-cta--delete
          "
          @click="$emit('deleteTempQuestion'), (deleteInfo = false)"
          v-html="langObj['s4-customQuestions'].previewDeleteYes"
        ></button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuestionSettings",
  props: {
    langObj: {
      type: Object,
    },
    setAsScreener: {
      type: Boolean,
    },
    showSetAsScreener: {
      type: Boolean,
    },
    saveInLibrary: {
      type: Boolean,
    },
    showSaveInLibrary: {
      type: Boolean,
    },
  },
  data() {
    return {
      deleteInfo: false,
      localSetAsScreener: this.setAsScreener,
      localSaveInLibrary: this.saveInLibrary,
    };
  },
  methods: {},
  watch: {
    localSetAsScreener(newVal) {
      this.$emit("setAsScreener", newVal);
    },
    localSaveInLibrary(newVal) {
      this.$emit("saveInLibrary", newVal);
    },
  },
};
</script>

<style lang="scss" scoped></style>
