<template>
  <div class="question-builder">
    <div class="question-builder__content input-group">
      <div class="mb-15">
        <label
          for=""
          v-html="langObj['s4-customQuestions'].modalLabel1"
        ></label>
        <a-textarea
          v-model.trim="questionData.questionText"
          :auto-size="{ minRows: 2 }"
        />
      </div>

      <div class="question-builder__options">
        <label
          ><span v-html="langObj['s4-customQuestions'].modalLabel2"></span>
          <span>{{ questionData.subQuestions.length }}/10</span></label
        >
        <div
          class="question-builder__options-list"
          :class="{
            'ant-input-group-addon-small': questionData.subQuestions.length < 3,
          }"
        >
          <draggable
            class="list-group"
            tag="ul"
            v-model="questionData.subQuestions"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            handle=".ant-btn--drag"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <a-input
                v-for="(option, index) in questionData.subQuestions"
                :placeholder="langObj['s4-customQuestions'].modalPlaceholder1"
                class="js-sub-questions-input options-input"
                :key="index"
                v-model="option.subQuestionText"
                @pressEnter="addSubQustions(index)"
              >
                <a-button
                  slot="addonAfter"
                  class="ant-btn--drag"
                  v-if="
                    questionData.subQuestions.length > 2 &&
                    index !== questionData.subQuestions.length - 1
                  "
                >
                  <font-awesome-icon icon="grip-vertical" />
                </a-button>
                <a-button
                  slot="addonAfter"
                  v-if="
                    option.subQuestionText === '' ||
                    (questionData.subQuestions[index + 1] === undefined &&
                      index + 1 < maxOptions)
                  "
                  @click="addSubQustions(index)"
                  v-html="langObj['s4-customQuestions'].modalButton3"
                >
                </a-button>
                <a-button
                  v-else
                  slot="addonAfter"
                  class="ant-btn--delete"
                  @click="removeSubQuestions(index)"
                  v-html="langObj['s4-customQuestions'].modalButton4"
                >
                </a-button>
              </a-input>
            </transition-group>
          </draggable>
        </div>
      </div>

      <div class="question-builder__options">
        <label for=""
          ><span v-html="langObj['s4-customQuestions'].modalLabel3"></span>
          <span>{{ questionData.options.length }}/10</span></label
        >
        <div
          class="question-builder__options-list"
          :class="{
            'ant-input-group-addon-small': questionData.options.length < 3,
          }"
        >
          <draggable
            class="list-group"
            tag="ul"
            v-model="questionData.options"
            v-bind="dragOptions"
            @start="drag = true"
            @end="drag = false"
            handle=".ant-btn--drag"
          >
            <transition-group
              type="transition"
              :name="!drag ? 'flip-list' : null"
            >
              <a-input
                v-for="(option, index) in questionData.options"
                :placeholder="langObj['s4-customQuestions'].modalPlaceholder2"
                class="js-options-input options-input"
                :key="index"
                v-model="option.optionText"
                @pressEnter="addOption(index)"
              >
                <a-button
                  slot="addonAfter"
                  class="ant-btn--drag"
                  v-if="
                    questionData.options.length > 2 &&
                    index !== questionData.options.length - 1
                  "
                >
                  <font-awesome-icon icon="grip-vertical" />
                </a-button>
                <a-button
                  slot="addonAfter"
                  v-if="
                    option.optionText === '' ||
                    (questionData.options[index + 1] === undefined &&
                      index + 1 < maxOptions)
                  "
                  @click="addOption(index)"
                  v-html="langObj['s4-customQuestions'].modalButton3"
                >
                </a-button>
                <a-button
                  v-else
                  slot="addonAfter"
                  class="ant-btn--delete"
                  @click="removeOption(index)"
                  v-html="langObj['s4-customQuestions'].modalButton4"
                >
                </a-button>
              </a-input>
            </transition-group>
          </draggable>
        </div>
      </div>
    </div>
    <div class="question-builder__cta">
      <span class="question-builder__cta-info">{{ successInfoMessage }}</span>
      <div>
        <a-tooltip
          placement="top"
          overlayClassName="ant-tooltip--small"
          :visible="cancelTooltip"
        >
          <template slot="title">
            <span
              v-html="langObj['s4-customQuestions'].modalCancelTooltip"
            ></span>
          </template>
          <a-button
            @mouseenter="setCancelTooltip"
            @mouseleave="cancelTooltip = false"
            @click="$emit('question-builde:close')"
            v-html="langObj['s4-customQuestions'].modalButton1"
          >
          </a-button>
        </a-tooltip>
        <a-button
          type="primary"
          @click="saveData"
          :disabled="disableAddBtn"
          v-html="langObj['s4-customQuestions'].modalButton2"
        >
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "GridTypeBuilder",
  props: {
    editMode: {
      type: Boolean,
    },
    questionInfo: {
      type: Object,
    },
    questionIndex: {
      type: Number,
    },
    langObj: {
      type: Object,
    },
    type: {
      type: String,
      default() {
        return "table-radio";
      },
    },
  },
  components: {
    draggable,
  },
  data() {
    return {
      successInfoMessage: null,
      cancelTooltip: false,
      maxOptions: 10,
      drag: false,
      questionData: {
        questionType: null,
        questionText: null,
        options: [{ optionText: "" }],
        subQuestions: [{ subQuestionText: "" }],
        sortOption: "SHUFFLE",
      },
    };
  },

  methods: {
    addOption(index) {
      if (this.questionData.options[index].optionText !== "") {
        if (this.questionData.options.length < this.maxOptions) {
          this.questionData.options.push({ optionText: "" });
          setTimeout(() => {
            const optionInputs = document.querySelectorAll(
              ".js-options-input input"
            );
            optionInputs[this.questionData.options.length - 1].focus();
          }, 200);
        }
      }
    },
    removeOption(index) {
      if (this.questionData.options.length === 1) {
        this.questionData.options[0] = { optionText: "" };
      } else {
        this.questionData.options.splice(index, 1);
      }
    },
    addSubQustions(index) {
      if (this.questionData.subQuestions[index].subQuestionText !== "") {
        if (this.questionData.subQuestions.length < this.maxOptions) {
          this.questionData.subQuestions.push({ subQuestionText: "" });
          setTimeout(() => {
            const subQuestionsInputs = document.querySelectorAll(
              ".js-sub-questions-input input"
            );
            subQuestionsInputs[
              this.questionData.subQuestions.length - 1
            ].focus();
          }, 200);
        }
      }
    },
    removeSubQuestions(index) {
      if (this.questionData.subQuestions.length === 1) {
        this.questionData.subQuestions[0] = { subQuestionText: "" };
      } else {
        this.questionData.subQuestions.splice(index, 1);
      }
    },
    saveData() {
      let getSubQuestions = this.questionData.subQuestions
        .filter((item) => item.subQuestionText !== "")
        .map((item) => {
          return { ...item };
        });
      let getOptions = this.questionData.options
        .filter((item) => item.optionText !== "")
        .map((item) => {
          return { ...item };
        });

      this.questionData.subQuestions = getSubQuestions;
      this.questionData.options = getOptions;
      this.successInfoMessage = this.langObj["s4-customQuestions"].modalSave1;
      this.$emit("question-data", this.questionData, this.questionIndex);
      this.questionData = {
        questionType: null,
        questionText: null,
        options: [{ optionText: "" }],
        subQuestions: [{ subQuestionText: "" }],
        sortOption: "SHUFFLE",
      };
      setTimeout(() => {
        this.successInfoMessage = this.langObj["s4-customQuestions"].modalSave2;
      }, 500);
      setTimeout(() => {
        this.successInfoMessage = null;
      }, 1500);
    },
    setCancelTooltip() {
      if (
        this.questionData.questionText !== null &&
        this.questionData.questionText !== "" &&
        this.questionData.options.filter((e) => e.optionText !== "").length > 1
      ) {
        this.cancelTooltip = true;
        setTimeout(() => {
          this.cancelTooltip = false;
        }, 2000);
      } else {
        this.cancelTooltip = false;
      }
    },
  },
  computed: {
    disableAddBtn() {
      return (
        this.questionData.questionText === null ||
        this.questionData.questionText === "" ||
        this.questionData.options.filter((e) => e.optionText !== "").length <
          2 ||
        this.questionData.subQuestions.filter((e) => e.subQuestionText !== "")
          .length < 1
      );
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
      };
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(newVal) {
        this.questionData = {
          questionType: newVal,
          questionText: null,
          options: [{ optionText: "" }],
          subQuestions: [{ subQuestionText: "" }],
          sortOption: "SHUFFLE",
        };
      },
    },
  },
  mounted() {
    if (this.editMode) {
      this.questionData = this.questionInfo.CustomQuestions[this.questionIndex];
    }
  },
};
</script>
