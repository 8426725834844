<template>
  <div class="multi-choice-example">
    <label>{{ exampleQuestionText }}</label>
    <a-checkbox v-for="(item, index) in options" :key="index">
      {{ item.optionText }}
    </a-checkbox>
    <QuestionSettings
      v-if="questionSettings"
      @editItem="editItem"
      @deleteTempQuestion="$emit('deleteTempQuestion')"
      @setAsScreener="$emit('setAsScreener', $event)"
      @saveInLibrary="$emit('saveInLibrary', $event)"
      :langObj="langObj"
      :setAsScreener="setAsScreener"
      :showSetAsScreener="showSetAsScreener"
      :saveInLibrary="saveInLibrary"
      :showSaveInLibrary="showSaveInLibrary"
    ></QuestionSettings>
  </div>
</template>

<script>
import QuestionSettings from "@/components/questions/QuestionSettings.vue";

export default {
  name: "MultiChoiceExample",
  props: {
    exampleQuestionText: {
      type: String,
    },
    options: {
      type: Array,
      default() {
        return [
          { optionText: "Lorem" },
          { optionText: "Ipsum" },
          { optionText: "Dolor" },
          { optionText: "Sit" },
          { optionText: "Amet" },
          { optionText: "Elit" },
        ];
      },
    },
    index: {
      type: Number,
    },
    langObj: {
      type: Object,
    },
    questionType: {
      type: String,
    },
    questionSettings: {
      type: Boolean,
    },
    setAsScreener: {
      type: Boolean,
    },
    showSetAsScreener: {
      type: Boolean,
    },
    saveInLibrary: {
      type: Boolean,
    },
    showSaveInLibrary: {
      type: Boolean,
    },
  },
  components: {
    QuestionSettings,
  },
  data() {
    return {
      radioBtnTypesStyle: {
        display: "block",
        height: "30px",
        lineHeight: "30px",
      },
    };
  },

  methods: {
    editItem() {
      let info = {
        index: this.index,
        questionType: this.questionType,
      };

      this.$emit("editQuestion", info);
    },
  },
};
</script>

<style lang="scss" scoped></style>
